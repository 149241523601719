import {
  Component,
  OnInit,
  Input,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

import { ModalPage } from './modal/modal.page';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('images') imagesData = [];

  private pinchEvents = [];
  public columns = 2;
  private wrapperSize = 0;

  private isAnimating = 0;
  private modalIsVisible = false;

  constructor(
    private el: ElementRef,
    private animationCtrl: AnimationController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.updateSize();
  }

  private async enterModalAnimation() {
    if (this.isAnimating === 1 || this.modalIsVisible === true) {
      return;
    }

    if (this.isAnimating === 2) {
      setTimeout(() => {
        this.enterModalAnimation();
      }, 50);
    }

    this.modalIsVisible = true;
    this.isAnimating = 1;
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(document.querySelector('.modal'))
      .duration(50)
      .easing('ease-in')
      .beforeStyles({
        display: 'flex',
      })
      .fromTo('opacity', 0, 1);

    await animation.play();
    this.isAnimating = 0;
  }

  private async leaveModalAnimation() {
    if (this.isAnimating === 2 || this.modalIsVisible === false) {
      return;
    }

    if (this.isAnimating === 1) {
      setTimeout(() => {
        this.leaveModalAnimation();
      }, 500);
    }

    this.modalIsVisible = false;
    this.isAnimating = 2;
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(document.querySelector('.modal'))
      .duration(50)
      .easing('ease-out')
      .afterStyles({
        display: 'none',
      })
      .fromTo('opacity', 1, 0);

    await animation.play();
    this.isAnimating = 0;
  }

  private updateSize() {
    const padding = 5; // 5px
    const parentWidth = window.innerWidth;

    const imgWidth =
      (parentWidth - padding * 2 - padding * 2 * this.columns) / this.columns;
    this.wrapperSize = imgWidth;
    this.el.nativeElement.style.setProperty(
      '--img-size',
      imgWidth.toString() + 'px'
    );
  }

  @HostListener('touchstart', ['$event']) onStart(ev) {
    this.registerFingerEvent(ev);
  }

  @HostListener('touchend', ['$event']) onEnd(ev) {
    this.removeFingerEvent(ev);

    if (ev.touches.length === 0) {
      this.leaveModalAnimation();
    }
  }

  @HostListener('touchmove', ['$event']) async onMove(ev) {
    if (ev.touches.length !== 2) {
      return;
    }

    const THRESHOLD = 10;

    const event = this.getEventFromIdentifier(ev);
    const deltaX = Math.abs(event.startX - ev.changedTouches[0].pageX);
    const deltaY = Math.abs(event.startY - ev.changedTouches[0].pageY);
    const delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (delta > THRESHOLD) {
      this.enterModalAnimation();
    }

    for (const e of ev.touches) {
      this.moveFingerTrace(e);
    }

    this.drawLine(ev);
  }

  private drawLine(ev) {
    const line = document.getElementById('line');
    let touch0 = ev.touches[0];
    let touch1 = ev.touches[1];
    if (ev.touches[0].identifier !== 0) {
      touch0 = ev.touches[1];
      touch1 = ev.touches[0];
    }
    const point0X = touch0.pageX;
    const point0Y = touch0.pageY;
    const point1X = touch1.pageX;
    const point1Y = touch1.pageY;
    const dist = Math.sqrt(
      Math.pow(point1X - point0X, 2) + Math.pow(point1Y - point0Y, 2)
    );

    this.updateColumns(dist);

    const finalXposition = (point0X + point1X) / 2 - dist / 2;
    const finalYposition = (point0Y + point1Y) / 2;

    let pointsRotation =
      Math.acos((point1X - point0X) / dist) * (180 / Math.PI);
    if (point1Y < point0Y) {
      pointsRotation *= -1;
    }

    line.style.setProperty('width', dist.toString() + 'px');
    line.style.setProperty(
      'transform',
      `translate3d(${finalXposition}px, ${finalYposition}px, 0px) rotate(${pointsRotation}deg)`
    );
  }

  private updateColumns(dist) {
    const max = 4;
    const min = 1;
    const sensitivity = 4;
    dist = Math.floor((dist / window.innerWidth) * sensitivity);
    const newColumns = max - Math.min(Math.max(dist, min), max) + 1;

    if (newColumns !== this.columns) {
      this.columns = newColumns;
      this.updateSize();
    }
  }

  private getEventFromIdentifier(ev) {
    return this.pinchEvents.find((event) => {
      return event.identifier === ev.changedTouches[0].identifier;
    });
  }

  private registerFingerEvent(ev) {
    this.pinchEvents.push({
      identifier: ev.changedTouches[0].identifier,
      startX: ev.changedTouches[0].pageX,
      startY: ev.changedTouches[0].pageY,
    });
  }

  private removeFingerEvent(ev) {
    this.pinchEvents = this.pinchEvents.filter((event) => {
      return event.identifier !== ev.changedTouches[0].identifier;
    });
  }

  private moveFingerTrace(e) {
    const fingerTrace = document.getElementById(
      'finger' + e.identifier.toString()
    );
    const positionX = e.pageX;
    const positionY = e.pageY;
    const fingerSize = 80;

    const newFingerPositionX = (positionX - fingerSize / 2).toString();
    const newFingerPositionY = (positionY - fingerSize / 2).toString();

    fingerTrace.style.setProperty(
      'transform',
      'translate3d(' +
        newFingerPositionX +
        'px, ' +
        newFingerPositionY +
        'px, 0px)'
    );
  }

  public async openImage(ev, src) {
    const modal = await this.modalCtrl.create({
      component: ModalPage,
      animated: false,
      cssClass: 'transparent-modal',
      componentProps: {
        src,
        wrapperSize: this.wrapperSize,
        positionX: ev.pageX - ev.offsetX,
        positionY: ev.pageY - ev.offsetY,
      },
    });
    return await modal.present();
  }
}
