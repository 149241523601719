import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'city',
    loadChildren: () => import('./city/city.module').then((m) => m.CityModule),
  },
  {
    path: 'entrustment-route',
    loadChildren: () =>
      import('./entrustment-route/entrustment-route.module').then(
        (m) => m.EntrustmentRouteModule
      ),
  },
  {
    path: 'light-route',
    loadChildren: () =>
      import('./light-route/light-route.module').then(
        (m) => m.LightRouteModule
      ),
  },
  {
    path: 'apostle-route',
    loadChildren: () =>
      import('./apostle-route/apostle-route.module').then(
        (m) => m.ApostleRouteModule
      ),
  },
  {
    path: 'gallery',
    loadChildren: () =>
      import('./gallery/gallery-page.module').then((m) => m.GalleryPageModule),
  },
  {
    path: 'spirit',
    loadChildren: () =>
      import('./spirit/spirit.module').then((m) => m.SpiritModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'light-considerations',
    loadChildren: () =>
      import('./light-considerations/light-considerations.module').then(
        (m) => m.LightConsiderationsModule
      ),
  },
  {
    path: 'entrustment-considerations',
    loadChildren: () =>
      import(
        './entrustment-considerations/entrustment-considerations.module'
      ).then((m) => m.EntrustmentConsiderationsModule),
  },
  {
    path: 'apostle-considerations',
    loadChildren: () =>
      import('./apostle-considerations/apostle-considerations.module').then(
        (m) => m.ApostleConsiderationsModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
