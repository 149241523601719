import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController, AnimationController } from '@ionic/angular';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.page.html',
    styleUrls: ['./modal.page.scss'],
})

export class ModalPage implements AfterViewInit {
    @ViewChild('image') image: any;
    @ViewChild('slides') slides: any;

    @Input() src;
    @Input() wrapperSize;
    @Input() positionX;
    @Input() positionY;

    public sliderOpts = {
        zoom: {
            maxRatio: 5,
        }
    };

    private translationX = 0;
    private translationY = 0;
    private scaleFactor = 1;

    private imageAnimation = this.animationCtrl.create()
        .easing('ease-out')
        .duration(300);

    private backgroundAnimation = this.animationCtrl.create()
        .fromTo('background', 'rgba(0,0,0,0)', 'rgba(0,0,0,1)')
        .easing('ease-out')
        .duration(300);

    constructor(private modalCtrl: ModalController, private animationCtrl: AnimationController, private el: ElementRef) { }

    async ngAfterViewInit() {
        this.imageAnimation.addElement(this.image.nativeElement);
        this.backgroundAnimation.addElement(this.el.nativeElement);

        const imageSize = this.getImageSize(this.src);
        const screenSize = { width: window.innerWidth, height: window.innerHeight };
        const imageRatio = imageSize.width / imageSize.height;

        let finalImageHeight = screenSize.width / imageRatio;
        let finalImageWidth = finalImageHeight * imageRatio;

        if (imageSize.width / imageSize.height < screenSize.width / screenSize.height) {
            finalImageWidth = screenSize.height * imageRatio;
            finalImageHeight = finalImageWidth / imageRatio;
        }

        const finalPositionY = (screenSize.height - finalImageHeight) / 2;
        this.scaleFactor = this.wrapperSize / screenSize.width;

        if (imageSize.width / imageSize.height > 1) {
            this.scaleFactor = this.wrapperSize / finalImageHeight;
        }

        let wrapperImagePosition = this.positionY + (this.wrapperSize - this.wrapperSize / imageRatio) / 2;
        this.translationY = wrapperImagePosition - finalPositionY - (finalImageHeight - (finalImageHeight * this.scaleFactor)) / 2;
        this.translationX = this.positionX - (screenSize.width - screenSize.width * this.scaleFactor) / 2;

        if (imageSize.width / imageSize.height > 1) {
            wrapperImagePosition = this.positionX + (this.wrapperSize - this.wrapperSize * imageRatio) / 2;
            this.translationX = wrapperImagePosition - (finalImageWidth - (finalImageWidth * this.scaleFactor)) / 2;
            this.translationY = this.positionY - finalPositionY - (finalImageHeight - finalImageHeight * this.scaleFactor) / 2;
        }

        this.imageAnimation.fromTo(
            'transform', `translate3d(${this.translationX}px, ${this.translationY}px, 0) scale3d(${this.scaleFactor}, ${this.scaleFactor}, 1)`,
            'translate3d(0,0,0) scale3d(1,1,1)');

        this.imageAnimation.play();
        await this.backgroundAnimation.play();

        this.imageAnimation.stop(); // Otherwise slides behave funny
    }

    private getImageSize(url) {
        const i = new Image();
        i.src = url;
        return { width: i.width, height: i.height };
    }

    public dismiss() {
        this.slides.getSwiper().then(async swiper => {
            swiper.zoom.out();
            this.backgroundAnimation.direction('reverse').play();
            await this.imageAnimation.direction('reverse').play();
            this.modalCtrl.dismiss({ });
        });
    }
}
