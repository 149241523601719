import {Component, OnInit} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SwUpdate} from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public appPages = [
        {
            title: 'Strona główna',
            url: '/main',
            icon: 'bookmarks',
        },
        {
            title: 'Miasto Ełk',
            url: '/city',
            icon: 'storefront',
        },
        {
            title: 'Tajemnice światła',
            url: '/light-route',
            icon: 'walk',
        },
        {
            title: 'Tajemnice zawierzenia',
            url: '/entrustment-route',
            icon: 'boat',
        },
        {
            title: 'Apostołowie Miłosierdzia',
            url: '/apostle-route',
            icon: 'analytics',
        },
        {
            title: 'Strefa Ducha',
            url: '/spirit',
            icon: 'sunny',
        },
        {
            title: 'Galeria',
            url: '/gallery',
            icon: 'image',
        },
        {
            title: 'Kontakt',
            url: '/contact',
            icon: 'mail',
        },
    ];
    public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private swUpdate: SwUpdate
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(
                (page) => page.title.toLowerCase() === path.toLowerCase()
            );
        }

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                window.location.reload();
            });
        }
    }

}
